import React, { useCallback, useEffect, useMemo } from 'react';
import usePageTitle from '~/app/hooks/usePageTitle';
import { useAppHeader } from '~/app/modules/appHeader';
import { useParams } from 'react-router-dom';
import { Machine, IssueDetails } from "~/types";
import { route } from "~/app/helpers/route";
import Carousel from "~/app/common/Carousel";
import StatusMessage from "~/issues/Details/statusMessage";
import Issue from "~/issues/Details/Issue";
import WorkOrder from "~/issues/Details/WorkOrder";
import Messages from "~/issues/Details/Messages";
import { useHistory } from 'react-router-dom';
import { ROUTES } from "~/app/Router";
import CloseIssueDetails from "~/issues/Details/ClosedIssueDetails";
import { useStoreActions, useStoreState } from '~/store';
import { notify } from '~/app/helpers/notify';
import useTranslation from '~/app/hooks/useTranslation';
import { useImageEditor } from '~/app/modules/imageEditor/ImageEditorProvider';
import CloseIssueDialog from './CloseIssueDialog';

export interface DetailsProps {
    issueDetail: IssueDetails
}

export type DetailsComponent = React.FC<DetailsProps>

const Details: DetailsComponent = ({ issueDetail }) => {
    const history = useHistory();
    const { setData } = useAppHeader();
    const { id } = useParams<{ id: Machine['id'] }>();
    const { t } = useTranslation("issue.success");
    const { openEditor } = useImageEditor();

    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const updateIssueImageAction = useStoreActions(actions => actions.issue.updateIssueImage);
    const IssueFetchAction = useStoreActions(actions => actions.issue.fetchIssue);

    const pageTitle = useMemo(() => {
        return issueDetail.issueNo ? `Feil #${issueDetail.issueNo}` : issueDetail.description
    }, [issueDetail]);

    usePageTitle(pageTitle);
    useEffect(() => {
        setData({
            title: pageTitle,
            subTitle: (issueDetail.machineIntern + " - " + issueDetail.machineName).toUpperCase(),
            backLink: history.length > 1 ? history.goBack : route(ROUTES.projects),
            showUser: false,
            machineId: '',
            projectId: '',
            showMachineMessages: false,
            isIssueDetailPage: true,
            notification: false,
            search: false
        });
    }, [setData, id, issueDetail, pageTitle, history]);

    const updateImages = useCallback((updatedImages: (string | File)[]): void => {
        if (netWorkStatus || !updatedImages) return

        updateIssueImageAction({ id: issueDetail.id, images: updatedImages })
            .finally(async () => {
                await IssueFetchAction(issueDetail.id)
                notify(t("issueUpdated"), "success");
            });
    }, [netWorkStatus, updateIssueImageAction, issueDetail.id, IssueFetchAction, t]);

    const handleImageEditSave = useCallback((editedImage: File | null, index: number): void => {
        if (!editedImage || index === -1) return;
        updateImages(issueDetail.images.map((image, i) => (i === index ? editedImage : image)));
    }, [updateImages, issueDetail.images]);

    const handleImageDelete = useCallback((index: number): void => {
        if (!issueDetail.images || index === -1) return;
        updateImages(issueDetail.images.filter((image, i) => i !== index));
    }, [updateImages, issueDetail.images]);

    const editFile = useCallback((index) => {
        if (issueDetail.images[index]) {
            openEditor(
                issueDetail.images[index],
                (editedImage) => handleImageEditSave(editedImage, index),
                () => handleImageDelete(index),
                "",
                true
            );
        }
    }, [handleImageDelete, handleImageEditSave, issueDetail.images, openEditor]);

    return (
        <>
            {!!issueDetail.images.length && <Carousel images={issueDetail.images} onImageClick={!netWorkStatus ? editFile : () => { }} />}
            <StatusMessage item={issueDetail} />
            <Issue issue={issueDetail} />
            {(issueDetail.workOrderNo && issueDetail.status === 2) && <WorkOrder {...issueDetail} />}
            {issueDetail.status === 3 && <CloseIssueDetails issue={issueDetail} />}
            <Messages issue={issueDetail} />
            <CloseIssueDialog />
        </>
    );
};

export default Details
