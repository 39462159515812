import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';

import { isCordova } from '~/cordova';

import store from '~/store';

import history from './browserHistory';

import './axiosConfig';
import './momentLocales';

import TenantProvider from '~/tenantProvider/Provider';
import UserProvider from '~/user/Provider';
import NetworkStatusProvider from '~/networkStatusProvider/Provider';
import WebSocketProvider from '~/webSocket/WebSocketProvider';
import SQLiteProvider from '~/sqlite/SQLite';
import ErrorProvider from './ErrorProvider/ErrorProvider';

import * as serviceWorker from './serviceWorker';

import App from '~/app/App';

import './index.scss';
import { ImageEditorProvider } from './app/modules/imageEditor/ImageEditorProvider';

isCordova && import('cordova_script');

function renderReactDom(): void {
  ReactDOM.render((
    <ErrorProvider>
      <StoreProvider store={store}>
        <NetworkStatusProvider>
          <TenantProvider>
            <UserProvider>
              <WebSocketProvider>
                <SQLiteProvider>
                  <ImageEditorProvider>
                    <Router history={history}>
                      <App />
                    </Router>
                  </ImageEditorProvider>
                </SQLiteProvider>
              </WebSocketProvider>
            </UserProvider>
          </TenantProvider>
        </NetworkStatusProvider>
      </StoreProvider>
    </ErrorProvider>
  ), document.getElementById('root'));
}

function faviconInit(): void {
  const faviconEl = document.querySelector('link[rel*="icon"]') as HTMLLinkElement;

  if (!faviconEl) {
    return;
  }

  if (!window.matchMedia) {
    faviconEl.href = process.env.PUBLIC_URL + '/favicon.ico';

    return;
  }

  const setFavicon = (darkMode?: boolean): void => {
    if (darkMode) {
      faviconEl.href = process.env.PUBLIC_URL + '/favicon-white.ico';
    } else {
      faviconEl.href = process.env.PUBLIC_URL + '/favicon-black.ico';
    }
  }

  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  mediaQuery.addEventListener('change', ({ matches }) => {
    setFavicon(matches);
  });

  setFavicon(mediaQuery.matches);
}

if (isCordova) {
  document.addEventListener('deviceready', renderReactDom, false);
} else {
  faviconInit();

  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
