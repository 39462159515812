import React from 'react';
import classes from './style.module.scss';
import useTranslation from '~/app/hooks/useTranslation';

export type HeaderComponent = React.FC;

const Header: HeaderComponent = () => {
    const { t } = useTranslation('issue.issueClose');
    return (
        <div className={classes['model-header']}>
            <div className={classes['header-content']}>
                <label className={classes['title']}>
                    {t('title')}
                </label>
            </div>
        </div>
    );
};

export default Header
