import React, { useCallback, useState } from "react"
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import LightBox from "~/app/common/LightBox";
import { useStoreState } from "~/store";

export interface CarouselProps {
    images: string[]
    onImageClick?: (index: number) => void
}

export type CarouselComponent = React.FC<CarouselProps>

const Carousel: CarouselComponent = ({ images, onImageClick }) => {
    const [counter, setCounter] = useState<number>(0);
    const [flexBoxOpen, setFlexBoxOpen] = useState<boolean>(false);
    const netWorkStatus = useStoreState(state => state.app.offline.status);

    const goToPreviousSlide = useCallback((e) => {
        e.stopPropagation();
        setCounter(counter > 0 ? counter - 1 : images.length - 1)
    }, [counter, setCounter, images]);

    const goToNextSlide = useCallback((e) => {
        e.stopPropagation();
        setCounter(counter < images.length - 1 ? counter + 1 : 0)
    }, [counter, setCounter, images]);

    const toggleFlexBox = useCallback(() => {
        setFlexBoxOpen(prev => !prev);
    }, []);

    const imageClickHandler = useCallback(() => {
        netWorkStatus ? toggleFlexBox() : onImageClick?.(counter);
    }, [counter, netWorkStatus, onImageClick, toggleFlexBox])

    return (
        <div className={classes['slider']}>
            <img src={images[counter]} alt={''} className={classes['image']} onClick={imageClickHandler} />
            {images.length > 1 && (
                <div className={classes['buttons']} onClick={imageClickHandler}>
                    <div className={`${classes['icon-div']} ${classes['left']}`}>
                        <Icon name="arrow_back_ios" className={classes['icon']} onClick={goToPreviousSlide} />
                    </div>
                    <div className={classes['text']}>
                        <p className={classes['slider-text']}>{`(${counter + 1}/${images.length})`}</p>
                    </div>
                    <div className={`${classes['icon-div']} ${classes['right']}`}>
                        <Icon name="arrow_back_ios" className={classes['icon']} onClick={goToNextSlide} />
                    </div>
                </div>
            )}
            {!!netWorkStatus && <LightBox open={flexBoxOpen} onClose={toggleFlexBox} imageUrl={images[counter]} />}
        </div>
    )
}

export default Carousel