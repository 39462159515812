import React, { createContext, useState, useContext, ReactNode } from "react";
import ImageEditor from "~/app/modules/imageEditor" // Import the editor

interface ImageEditorContextProps {
    openEditor: (image: File | string, onSave: (editedImage: File) => void, onDelete?: () => void, imageSource?: string, showDeleteOption?: boolean, onSubmitWithoutEdit?: () => void) => void;
    closeEditor: () => void;
}

const ImageEditorContext = createContext<ImageEditorContextProps | null>(null);

export const useImageEditor = () => {
    const context = useContext(ImageEditorContext);
    if (!context) {
        throw new Error("useImageEditor must be used within an ImageEditorProvider");
    }
    return context;
};

export const ImageEditorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [imageToEdit, setImageToEdit] = useState<File | string | null>(null);
    const [imageSource, setImageSource] = useState<string | null>(null);
    const [showDeleteOption, setShowDeleteOption] = useState<boolean>(false);
    const [onSaveCallback, setOnSaveCallback] = useState<(file: File) => void>(() => () => {});
    const [onDeleteCallback, setOnDeleteCallback] = useState<(() => void) | null>(null);
    const [onSubmitWithoutEditCallback, setonSubmitWithoutEditCallback] = useState<(() => void) | null>(null);

    const openEditor = (image: File | string, onSave: (editedImage: File) => void, onDelete?: () => void, source?: string, showDeleteOption?: boolean, onSubmitWithoutEdit?: () => void) => {
        setImageToEdit(image);
        setOnSaveCallback(() => onSave);
        setIsOpen(true);
        setOnDeleteCallback(() => onDelete || null);
        setImageSource(source || null);
        setShowDeleteOption(showDeleteOption || false);
        setonSubmitWithoutEditCallback(() => onSubmitWithoutEdit);
    };
    
    const closeEditor = () => {
        setIsOpen(false);
        setImageToEdit(null);
        setImageSource(null); 
        setOnDeleteCallback(null);
        setonSubmitWithoutEditCallback(null);
    };

    return (
        <ImageEditorContext.Provider value={{ openEditor, closeEditor }}>
            {children}
            {isOpen && (
                <ImageEditor
                    isOpen={isOpen}
                    imageUrlOrObject={imageToEdit}
                    onClose={closeEditor}
                    imageSource={imageSource}
                    onImageSave={(editedImage) => {
                        if (editedImage) {
                            onSaveCallback(editedImage);
                        }
                        closeEditor();
                    }}
                    onDelete={() => {
                        if (onDeleteCallback) onDeleteCallback(); 
                        closeEditor();
                    }}
                    showDeleteOption={showDeleteOption}
                    onSubmitWithoutEdit={() => {
                        onSubmitWithoutEditCallback?.();
                        closeEditor();
                    }}
                />
            )}
        </ImageEditorContext.Provider>
    );
};