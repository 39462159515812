import React, { useCallback, useState } from 'react';
import useField from '../useField';
import useDefaultValue from '../useDefaultValue';
import Field, { FieldProps } from '../Field';
import classes from './style.module.scss';
import RadioCheck, { RadioCheckProps } from '~/app/modules/form/RadioCheck';
import { DEFAULT_ISSUE_CLOSE_TYPE } from '~/app/data/statuses/issue';

export interface RadioCheckGroupPropsDefault extends Omit<RadioCheckProps, 'defaultValue' | 'onChange'> {
  options: RadioCheckProps[],
  defaultValue?: RadioCheckProps['value']
  onChange?: RadioCheckProps['onChange'];
  inputClassName?: string
}

export type RadioCheckGroupProps = RadioCheckGroupPropsDefault & FieldProps

export type RadioCheckGroupComponent = React.FC<RadioCheckGroupProps>

const RadioCheckGroup: RadioCheckGroupComponent = props => {
  const {
    fieldProps,
    inputProps: {
      options,
      defaultValue,
      onChange,
      ...radioCheckProps
    }
  } = useField<RadioCheckGroupPropsDefault>(props);

  const initialValue = useDefaultValue(defaultValue || DEFAULT_ISSUE_CLOSE_TYPE);
  
  const [selectedValue, setSelectedValue] = useState<RadioCheckProps['value']>(String(initialValue));

  const inputChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setSelectedValue(value);

    // @ts-ignore
    onChange?.({ ...e, target: { ...e.target, name: e.target.name, value: value ?? '' } });
  }, [onChange]);

  return (
    <Field {...fieldProps}>
      <div className={classes['group']}>
        {options.map(option => (
          <RadioCheck
            key={(option.value as string)}
            {...radioCheckProps}
            {...option}
            name={props.name}
            isGroupItem
            inputClassName={props?.inputClassName || classes['input']}
            placeholder={option.label}
            checked={selectedValue === String(option.value)}
            onChange={inputChangeHandler}
          />
        ))}
      </div>
    </Field>
  );
};

export default RadioCheckGroup
