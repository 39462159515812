import React from 'react';
import { useHistory } from 'react-router';
import { ToastContainer, Slide, toast } from 'react-toastify';

import { route } from '~/app/helpers/route';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';
import { AppHeader } from '~/app/modules/appHeader';
import { AppLoader } from '~/app/modules/appLoader';

import Router from '~/app/Router';

import store from '~/store';

import { MACHINES_DETAILS_ROUTES } from '~/machines/Details/Router';

import Sidebar from './Sidebar';
import Content from './Content';
import MainContent from './MainContent';
import Footer from './Footer';
import Messages from './Messages';

import classes from './style.module.scss';
import AccessControl from "~/accessControl";
import { PERMISSION_GROUPS } from "~/accessControl/permissionGroups";

export type AddComponent = React.FC

const App: AddComponent = () => {

  const history = useHistory();

  useDidMountEffect(() => {
    if (window['cordova']) {

      window['cordova'].plugins.notification.local.requestPermission(() => {
        console.log('Local push messaging is allowed');
      });

      window['FirebasePlugin'].onMessageReceived(function(message) {
          console.log(message, 'message');
          console.log("Message type: " + message.messageType);
          if(message.messageType === "notification"){
              console.log("Notification message received");
              if(message.tap){
                  console.log("Tapped in " + message.tap);
                  if (message && message.issueId && message.machineId) {
                      history.push(route(MACHINES_DETAILS_ROUTES.issueDetails, {
                          id: message.machineId,
                          issueId: message.issueId,
                      }));
                  }
              } else {
                  console.log("Data received in foreground");
                  if (message.issueId && !(store.getState().issue.data.id === message.issueId && store.getState().app.appHeader.isIssueDetailPage)) {
                      window['cordova'].plugins.notification.local.schedule({
                          title: message.title,
                          text: message.body,
                          ...window['cordova']?.platformId === 'ios' && {iOSForeground: true},
                          data: message,
                          ...window['cordova']?.platformId === 'android' && {androidSmallIcon: 'res://notification_icon.png'}
                      });
                  }
              }
          }
      }, function(error) {
        console.error(error);
      });

      window['cordova'].plugins.notification.local.on('click', payload => {
        console.log(payload, 'payload');
        if (payload.data && payload.data.issueId && payload.data.machineId) {
          history.push(route(MACHINES_DETAILS_ROUTES.issueDetails, {
            id: payload.data.machineId,
            issueId: payload.data.issueId,
          }));
        }
      });
    }
  });

  return (
    <div id={classes['app']}>
      <div className={classes['main-wrap']}>
        <AppHeader />

        <MainContent>
          <Messages />

          <Content>
            <AccessControl requiredPermissions={PERMISSION_GROUPS.app} showMessage={true}>
              <Router />
            </AccessControl>
          </Content>

          <Footer />
        </MainContent>
      </div>

      <Sidebar />

      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        transition={Slide}
        closeButton={false}
        closeOnClick
        hideProgressBar
      />

      <AppLoader />
    </div>
  );
};

export default App;
