import React from 'react';
import Modal from '~/app/common/Modal';
import { COLOR_OPTIONS } from '~/app/data/statuses/issue';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';

export interface ColorPickerProps {
    isOpen: boolean
    onColorChange: (color: string) => void
    currentColor?: string
    onClose?: () => void
}

export type ColorPickerComponent = React.FC<ColorPickerProps>;

const ColorPicker: ColorPickerComponent = ({ isOpen, onColorChange, currentColor, onClose }) => {
    const { t } = useTranslation("issue.imageEditor.colorPicker");

    return (
        <Modal className={`${classes['color-picker']} ${classes['bottom']}`} open={isOpen} onClose={onClose} position={'bottom'}>
            <div className={classes['model-header']}>
                <div className={classes['header-content']}>
                    <label className={classes['title']}>{t('colors')}</label>
                </div>
                <Icon name={'close'} size={'24'} className={classes['close-icon']} onClick={onClose} />
            </div>
            <div className={classes['modal-body']}>
                <div className={classes['color-palette']}>
                    {COLOR_OPTIONS.map((color) => (
                        <button
                            key={color}
                            onClick={() => onColorChange(color)}
                            className={`${classes['color']} ${currentColor === color ? classes['selected'] : ''}`}
                            style={{ backgroundColor: color }}
                        />
                    ))}
                    {/* Transparent Button As per UI requirement - empty space available at right side */}
                    <button className={classes['color']} style={{ backgroundColor: 'transparent' }} />
                </div>
            </div>
        </Modal>
    );
};

export default ColorPicker;