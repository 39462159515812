import { useEffect, useRef, useState, useCallback } from "react";
import { fabric } from "fabric";
import { dataURLtoBolb, getImageFileInfo } from "~/issues/helpers";
import { 
    DEFAULT_EDIT_COLOR, 
    EDIT_IMAGE_DEFAULT_EXTENSION, 
    EDIT_IMAGE_DEFAULT_MIME_TYPE, 
    EDIT_IMAGE_EXPORT_QUALITY, 
    EDIT_IMAGE_RESOLUTION_MULTIPLIER 
} from '~/app/data/statuses/issue';

export const useCanvasEditor = (imageUrlOrObject: File | string | null, isOpen: boolean) => {
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const historyRef = useRef<{ version: string; objects: fabric.Object[] }[]>([]);
    const currentHistoryStep = useRef<number>(-1);

    const [brushColor, setBrushColor] = useState(DEFAULT_EDIT_COLOR);
    const [isDrawingActive, setIsDrawingActive] = useState(false);
    const [editedImageFile, setEditedImageFile] = useState<File | null>(null);
    const [historyIndex, setHistoryIndex] = useState(-1);

    useEffect(() => {
        if (!isOpen || !imageUrlOrObject || canvasRef.current) return;

        const imageUrl = typeof imageUrlOrObject === 'string' 
            ? imageUrlOrObject 
            : URL.createObjectURL(imageUrlOrObject);

        const canvas = new fabric.Canvas('fabricCanvas', {
            isDrawingMode: false,
            selection: false,
            backgroundColor: 'transparent',
            preserveObjectStacking: true
        });

        canvas.freeDrawingBrush.color = DEFAULT_EDIT_COLOR;
        canvas.freeDrawingBrush.width = 3;
        canvasRef.current = canvas;

        const canvasWidth = window.innerWidth;
        const canvasHeight = window.innerHeight * 0.61;
        canvas.setDimensions({ width: canvasWidth, height: canvasHeight });

        fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
            if (!img) return;

            img.set({
                selectable: false,
                evented: false,
                left: 0,
                top: 0,
                scaleX: canvasWidth / img.width!,
                scaleY: canvasHeight / img.height!,
            });

            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            setTimeout(saveCanvasState, 100);
        }, { crossOrigin: 'anonymous' });

        canvas.on('path:created', saveCanvasState);

        return () => {
            if (canvasRef.current) {
                canvas.off('path:created', saveCanvasState);
                canvasRef.current.dispose();
                canvasRef.current = null;
            }
        };
    }, [isOpen, imageUrlOrObject]);

    useEffect(() => {
        if (canvasRef.current?.isDrawingMode && canvasRef.current.freeDrawingBrush) {
            canvasRef.current.freeDrawingBrush.color = brushColor;
        }
    }, [brushColor]);

    const saveCanvasState = () => {
        if (!canvasRef.current) return;
        historyRef.current = historyRef.current.slice(0, currentHistoryStep.current + 1);
        const canvasSnapshot = canvasRef.current.toJSON(['backgroundImage']);
        historyRef.current.push(canvasSnapshot);
        currentHistoryStep.current++;

        setHistoryIndex(currentHistoryStep.current);
    };

    const undoAction = () => {
        if (currentHistoryStep.current > 0) {
            currentHistoryStep.current--;
            setHistoryIndex(currentHistoryStep.current);
            canvasRef.current?.loadFromJSON(historyRef.current[currentHistoryStep.current], () => {
                canvasRef.current?.renderAll();
            });
        }
    };

    const redoAction = () => {
        if (currentHistoryStep.current < historyRef.current.length - 1) {
            currentHistoryStep.current++;
            setHistoryIndex(currentHistoryStep.current);
            canvasRef.current?.loadFromJSON(historyRef.current[currentHistoryStep.current], () => {
                canvasRef.current?.renderAll();
            });
        }
    };

    const exportEditedImage = useCallback(() => {
        if (!canvasRef.current || !imageUrlOrObject) return;

        const canvasElement = canvasRef.current?.toCanvasElement?.() || canvasRef.current?.getElement?.();
        if (!canvasElement) return;

        let originalName = Date.now().toString();
        let fileType = EDIT_IMAGE_DEFAULT_MIME_TYPE;
        let fileExtension = EDIT_IMAGE_DEFAULT_EXTENSION;

        if (typeof imageUrlOrObject === 'string') {
            const { filename, extension, mimeType } = getImageFileInfo(imageUrlOrObject);
            originalName = filename || Date.now().toString();
            fileExtension = extension || EDIT_IMAGE_DEFAULT_EXTENSION;
            fileType = mimeType || EDIT_IMAGE_DEFAULT_MIME_TYPE;
        } else {
            originalName = imageUrlOrObject.name.split('.').slice(0, -1).join('.') || Date.now().toString();
            fileType = imageUrlOrObject.type || EDIT_IMAGE_DEFAULT_MIME_TYPE;
            fileExtension = fileType.split('/')[1] || EDIT_IMAGE_DEFAULT_EXTENSION;
        }

        const newFileName = `${originalName}-edited.${fileExtension}`;
        const editedImageDataURL = canvasRef.current.toDataURL({
            format: fileType,
            quality: EDIT_IMAGE_EXPORT_QUALITY,
            multiplier: EDIT_IMAGE_RESOLUTION_MULTIPLIER,
        });

        const blob = dataURLtoBolb(editedImageDataURL);
        if (blob) {
            const editedImageFile = new File([blob], newFileName, {
                type: fileType,
                lastModified: Date.now(),
            });
            setEditedImageFile(editedImageFile);
        }
        
        // Create a File from Blob
        if (blob) {
            // For mobile app, Convert blob manually through file reader
            if (window['cordova']) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onloadend = () => {
                    if (reader.result) {
                        const blobData: any = new Blob([reader.result], { type: fileType });
                        blobData.name = newFileName; // Manually set name
                        blobData.lastModified = Date.now();
                        setEditedImageFile(blobData);
                        
                    }
                };
            } else { // For web app
                const editedImageFile = new File([blob], newFileName, {
                    type: fileType,
                    lastModified: Date.now()
                });
                setEditedImageFile(editedImageFile);
            }
        } else {
            console.error("Error while exporting edited image: invalid blob");
        }
    }, [imageUrlOrObject]);

    const toggleDrawingMode = useCallback(() => {
        canvasRef.current && (canvasRef.current.isDrawingMode = !isDrawingActive);
        setIsDrawingActive(!isDrawingActive);
    }, [isDrawingActive]);
    
    const resetHistory = useCallback(() => {
        if (!canvasRef.current) return;

        historyRef.current = [];
        currentHistoryStep.current = -1;
        setHistoryIndex(-1);
        saveCanvasState();
        isDrawingActive && toggleDrawingMode();

    }, [isDrawingActive, toggleDrawingMode]);

    const resetCanvas = useCallback(() => {
        if (!canvasRef.current) return;

        const initialState = historyRef.current[0]; // First saved state
        canvasRef.current.clear().renderAll();
        canvasRef.current.loadFromJSON(initialState, () => canvasRef.current?.renderAll());
        resetHistory();

    }, [resetHistory]);

    const checkAndSaveEditedImage = useCallback(() => {
        if(historyRef.current.length > 0) {
            historyRef.current.length > 0 && exportEditedImage();
            resetHistory();
        }
    }, [exportEditedImage, resetHistory])

    return {
        canvasRef,
        historyRef,
        brushColor,
        setBrushColor,
        isDrawingActive,
        toggleDrawingMode,
        undoAction,
        redoAction,
        exportEditedImage,
        checkAndSaveEditedImage,
        editedImageFile,
        historyIndex,
        resetHistory,
        resetCanvas
    };
};
