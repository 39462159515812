import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Icon from '~/app/common/Icon';
import useField from '../useField';
import Field, { FieldProps } from '../Field';
import mainClasses from '../style.module.scss';
import classes from './style.module.scss';

export interface RadioCheckPropsDefault extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'placeholder'> {
    isGroupItem?: boolean
    inputClassName?: string
    placeholder?: React.ReactNode
    checked?: boolean; 
}

export type RadioCheckProps = RadioCheckPropsDefault & FieldProps

export type RadioCheckComponent = React.FC<RadioCheckProps>

const RadioCheck: RadioCheckComponent = props => {
    const {
        fieldProps,
        inputProps: {
            isGroupItem,
            inputClassName,
            placeholder,
            onChange,
            checked,
            ...othersInputProps
        }
    } = useField<RadioCheckPropsDefault>(props);

    const inputChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange?.(e);
    }, [onChange]);

    const item = useMemo(() => (
        <label className={classNames(`${mainClasses['input']} ${classes['input']}`, inputClassName)}>
            <Icon className={`${classes['icon']} ${checked ? classes['checked'] : ''}`} name={checked ? 'radio_button_checked' : 'radio_button_unchecked'} />

            {(!!placeholder) && <div className={classes['placeholder']}>{placeholder}</div>}

            <input
                {...othersInputProps}
                className={classes['radio-check-input']}
                type="radio"
                disabled={fieldProps.loading || othersInputProps.disabled}
                onChange={inputChangeHandler}
            />
        </label>
    ), [checked, fieldProps.loading, inputChangeHandler, inputClassName, othersInputProps, placeholder]);

    if (isGroupItem) {
        return item;
    }

    return (
        <Field {...fieldProps}>
            {item}
        </Field>
    );
};

export default RadioCheck
