import {AxiosResponse} from "axios";
import {getFileNameFromUrl} from "~/user/helpers";

export default class FileTransferService {
    public downloadFilesFromURL(urls: (string)[], folderName: string = '') {
        var allPromise: Promise<string>[] = [];
        for (let i = 0; i < urls.length; i++) {
            allPromise.push(this.downloadFileFromURL(urls[i], folderName));
        }

        return Promise.all(allPromise).then(files => {
            return files;
        });
    }

    public downloadFileFromURL(url: string, folderName: string = '') {
        return new Promise<string>((resolve) => {
            window['resolveLocalFileSystemURL'](this.getFileNameFromURL(url, folderName), () => {
                resolve(`${folderName}/${getFileNameFromUrl(url)}`)
            }, () => {
                console.log('plugin.http.sendRequest');
                const options = {method: 'GET', responseType: 'blob'};
                window['cordova'].plugin.http.sendRequest(url, options, (response) => {
                    if (response.status === 200) {
                        let file: any = new Blob([response.data], {type: response.headers['content-type']});
                        file.name = url.split('?')[0].split('/').pop();
                        console.log('file.name',file.name);
                        file.lastModified = response.headers['last-modified'];
                        this.saveFileFromBlob(file, folderName).then((path) => resolve(`${folderName}/${file.name}`));
                    } else {
                        resolve('');
                    }
                }, () => resolve(''));
            });
        });
    }

    public getFileNameFromURL(url: string, folderName: string = '') {
        return window['cordova'].file.dataDirectory + (folderName ? folderName + '/' : '') + url.split('?')[0].split('/').pop();
    }

    public getFolderPath(cacheDirectory = false) {
        return cacheDirectory ? window['cordova'].file.cacheDirectory : window['cordova'].file.dataDirectory;
    }

    public saveFilesFromBlobs(blobFile: File[] = [], folderName: string = 'temporary') {
        var allPromise: Promise<string>[] = [];
        for (let i = 0; i < blobFile.length; i++) {
            allPromise.push(this.saveFileFromBlob(blobFile[i], folderName));
        }

        return Promise.all(allPromise).then(files => {
            return files.map(path => `${folderName}/${getFileNameFromUrl(path)}`);
        });
    }

    public createFile(directory, blobFile: File, resolve) {
        directory.getFile(blobFile.name, {create: true, exclusive: false}, (fileEntry) => {
            fileEntry.createWriter((fileWriter) => {
                fileWriter.onwriteend = () => resolve(window['WkWebView'] ? window['WkWebView'].convertFilePath(fileEntry.toURL()) : fileEntry.toURL());
                fileWriter.onerror = () => resolve('');
                fileWriter.write(blobFile);
            });
        }, () => resolve(''));
    }

    public createDirectory(directory, blobFile: File, folderName: string = '', resolve) {
        if (folderName !== '') {
            directory.getDirectory(folderName.split("/")[0], {create: true}, (subdirectory) => {
                this.createDirectory(subdirectory, blobFile, folderName.split('/').slice(1).join('/'), resolve);
            }, () => resolve(''));
        } else {
            this.createFile(directory, blobFile, resolve);
        }
    }

    public removeFolder(folderUrl: string){
        const uri = `${this.getFolderPath()}/${folderUrl}`;
        window['resolveLocalFileSystemURL'](uri, (dirEntry) => {
            dirEntry.removeRecursively(
                () => console.log('successfully deleted the folder and its content'),
                e => console.error('there was an error deleting the directory', e.toString())
            )
        });
    }

    public saveFileFromBlob(blobFile: File, folderName: string = '', cacheDirectory: boolean = false) {
        return new Promise<string>((resolve) => {
            window['resolveLocalFileSystemURL'](this.getFolderPath(cacheDirectory), (directory) => {
                this.createDirectory(directory, blobFile, folderName, resolve);
            }, () => resolve(''));
        });
    }

    public getBlobsFromURLs(files: string[] = []) {
        var allPromise: Promise<File>[] = [];
        for (let i = 0; i < files.length; i++) {
            allPromise.push(this.getBlobFromURL(`${window['cordova'].file.dataDirectory}/${files[i]}`));
        }

        return Promise.all(allPromise).then(files => {
            return files;
        });
    }

    public getBlobFromURL(url: string) {
        return new Promise<File>((resolve) => {
            window['resolveLocalFileSystemURL'](url,
                (fileEntry) => {
                    fileEntry.file((file) => {
                        let reader = new FileReader();
                        reader.readAsArrayBuffer(file);
                        reader.onload = () => {
                            if (reader.result) {
                                let fileObj: any = new Blob([reader.result], {type: file.type});
                                fileObj.name = file.name;
                                fileObj.lastModified = file.lastModified;
                                resolve(fileObj);
                            }
                            resolve();
                        }
                        reader.onerror = () => resolve();
                    }, () => resolve());
                }, () => resolve())
        });
    }

    public removeFiles(files: string[] = []) {
        var allPromise: Promise<void>[] = [];
        for (let i = 0; i < files.length; i++) {
            allPromise.push(this.removeFile(`${window['cordova'].file.dataDirectory}/${files[i]}`));
        }

        return Promise.all(allPromise).then(files => {
            return files;
        });
    }

    public removeFile(url: string) {
        return new Promise<void>((resolve) => {
            const filePath = url.substr(0, url.lastIndexOf('/'));
            const fileName = url.substr(url.lastIndexOf('/') + 1);
            window['resolveLocalFileSystemURL'](filePath, (directory) => {
                directory.getDirectory(fileName, {create: true}, (fileEntry) => {
                    fileEntry.remove(() => resolve(), (error) => resolve(), () => resolve());
                }, () => resolve());
            }, () => resolve());
        });
    }

    public isFileExist(url: string, folderName: string = '') {
        if (window['cordova']) {
            return new Promise<string>((resolve) => {
                window['resolveLocalFileSystemURL'](this.getFileNameFromURL(url, folderName), () => {
                    resolve(this.getFileNameFromURL(url, folderName))
                }, () => resolve(''));
            });
        } else {
            return '';
        }
    }

    public openFile(path: string, mimeType: string = '') {
        if (window['cordova']) {
            window['cordova'].plugins.fileOpener2.open(path, mimeType, {
                error: (e) => console.log('Error status: ' + e.status + ' - Error message: ' + e.message),
                success: () => console.log('file opened successfully')
            });
        } else {
            window.open(path, '_blank');
        }
    }

    public saveFileFromBlobResponse(response: AxiosResponse, url: string, folderName: string = '') {
        let file: any = new Blob([response.data], {type: response.headers['content-type']});
        file.name = url.split('?')[0].split('/').pop();
        file.lastModified = response.headers['last-modified'];
        return this.saveFileFromBlob(file, folderName).then((path) => {
            return path;
        });
    }
}
