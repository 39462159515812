import {action, Action, computed, Computed, thunk, Thunk} from 'easy-peasy';
import {jsonToFormData} from '~/app/helpers/headerContentType'
import {Issue, IssueCategory, IssueClosePayload, IssueDetails, IssueImagePayload, IssuePayload, IssueResponsePayload, Message} from '~/types';
import {StoreModel} from "~/store";
import {API} from "~/app/routes";
import {CommonModel, fetch, fetchCancel, getCommonModel} from "~/store/commonStore";
import axios from "axios";
import {IssueOfflineService} from "~/sqlite/services/issue";
import {IssueStatuses, IssueTypes} from "~/app/data/statuses/issue";

export interface IssueModel extends CommonModel<Issue> {
    categories: IssueCategory[]
    fetchingIssueCategories: boolean
    setFetchingIssueCategories: Action<IssueModel, boolean>
    fetchIssueCategories: Thunk<IssueModel, void, any, StoreModel, Promise<IssueCategory[]>>
    fetchIssueCategoriesCancel: Action<IssueModel>
    fetchedIssueCategories: Action<IssueModel, IssueCategory[]>
    setOfflineIssueCategories: Thunk<IssueModel, IssueCategory[], any, StoreModel, Promise<void>>
    getOfflineIssueCategories: Thunk<IssueModel, void, any, StoreModel, Promise<void>>

    issueUpdating: boolean
    setIssueUpdating: Action<IssueModel, boolean>
    saveIssue: Thunk<IssueModel, IssuePayload, any, StoreModel, Promise<IssueResponsePayload>>

    showCompletedIssues: boolean
    setShowCompletedIssues: Action<IssueModel, boolean>

    filteredIssues: Computed<IssueModel, Issue[], StoreModel>
    filteredOpenIssues: Computed<IssueModel, Issue[], StoreModel>

    data: IssueDetails,
    fetchIssue: Thunk<IssueModel, IssueDetails['id'], any, StoreModel, Promise<void>>
    fetchedIssue: Action<IssueModel, IssueDetails>
    fetchFavoriteMachinesIssues: Thunk<IssueModel, IssueDetails['id'], any, StoreModel, Promise<void>>
    addChatMessage: Action<IssueModel, Message>
    addChatUser: Thunk<IssueModel, IssueDetails['id'], any, StoreModel, Promise<void>>
    addedChatUser: Action<IssueModel>

    setOfflineFavoriteMachinesIssues: Thunk<IssueModel, IssueDetails[], any, StoreModel, Promise<void>>
    fetchOfflineIssue: Thunk<IssueModel, IssueDetails['id'], any, StoreModel, Promise<void>>
    setOfflineIssue: Thunk<IssueModel, IssueDetails, any, StoreModel, Promise<void>>

    saveOfflineIssue: Thunk<IssueModel, IssuePayload, any, StoreModel, Promise<IssueResponsePayload>>

    updateIssueImage: Thunk<IssueModel, IssueImagePayload, any, StoreModel, Promise<void>>

    closeIssue: Thunk<IssueModel, IssueClosePayload, any, StoreModel, Promise<void>>
}

export const issueModel: IssueModel = {
    ...getCommonModel<Issue>(API.issue),

    categories: [],
    fetchingIssueCategories: false,
    setFetchingIssueCategories: action((state, payload) => {
        state.fetchingIssueCategories = payload;
    }),
    fetchIssueCategories: thunk((actions) => {
        actions.setFetchingIssueCategories(true);
        return fetch<IssueCategory>(`${API.issue}/categories`)
            .then(data => {
                actions.fetchedIssueCategories(data);
                actions.setOfflineIssueCategories(data);
                return data;
            })
            .finally(() => {
                actions.setFetchingIssueCategories(false);
            });
    }),
    fetchIssueCategoriesCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedIssueCategories: action((state, payload) => {
        state.categories = payload;
    }),
    setOfflineIssueCategories: thunk(async (actions, payload) => {
        const IssueOffline = new IssueOfflineService();
        await IssueOffline.resetIssueCategories(payload);
    }),
    getOfflineIssueCategories: thunk(async (actions) => {
        const IssueOffline = new IssueOfflineService();
        let issueCategories = await IssueOffline.getAllIssueCategories();
        issueCategories && actions.fetchedIssueCategories(issueCategories);
    }),

    issueUpdating: false,
    setIssueUpdating: action((state, payload) => {
        state.issueUpdating = payload;
    }),
    saveIssue: thunk((actions, payload) => {
        actions.setIssueUpdating(true);
        return axios.post<null, IssueResponsePayload>(`${API.issue}/save`, jsonToFormData(payload)).then(
            data => {
                return data;
            }
        ).finally(() => {
            actions.setIssueUpdating(false);
        })
    }),

    showCompletedIssues: false,
    setShowCompletedIssues: action((state, payload) => {
        state.showCompletedIssues = payload;
    }),

    filteredIssues: computed(
        [
            state => state.showCompletedIssues,
            (state, storeState) => storeState.machine.data.issues
        ],
        (status, issues) => issues.filter(issue =>
            status ? (issue.status === IssueStatuses.COMPLETED || issue.status === IssueStatuses.CLOSED) : !(issue.status === IssueStatuses.COMPLETED || issue.status === IssueStatuses.CLOSED)
        )
    ),
    filteredOpenIssues: computed(
        [
            (state, storeState) => storeState.machine.data.issues
        ],
        (issues) => issues.filter(issue => !(issue.status === IssueStatuses.COMPLETED || issue.status === IssueStatuses.CLOSED) && [IssueTypes.CRITICAL, IssueTypes.MORECRITICAL].includes(issue.type))
    ),

    data: {
        id: '',
        type: 0,
        description: '',
        status: 0,
        statusDate: '',
        comment: '',
        seenDate: '',
        images: [],
        categoryId: '',
        categoryName: '',
        issueNo: '',
        machineId: '',
        machineIntern: '',
        machineName: '',
        departmentName: '',
        departmentId: '',
        issueDepartmentName: '',
        issueDepartmentId: '',
        workOrderNo: '',
        workDetails: [],
        chat: [],
        isChatUser: false,
        createdAt: '',
        updatedAt: '',
        createdBy: '',
        closedAt: null,
        closedBy: null,
        issueCreatedUserId: null,
        issueCreatedBy: null,
        issueUpdatedBy: null,
        projectId: null
    },
    fetchIssue: thunk((actions, payload, {getStoreActions}) => {
        return axios.get<IssueDetails, IssueDetails>(`${API.issue}/${payload}`)
            .then(data => {
                actions.fetchedIssue(data);
                actions.setOfflineIssue(data);
            });
    }),
    fetchedIssue: action((state, payload) => {
        state.data = payload;
    }),
    fetchFavoriteMachinesIssues: thunk((actions, payload) => {
        return axios.get<IssueDetails, IssueDetails[]>(`${API.issue}/${payload}`)
            .then(data => {
                actions.setOfflineFavoriteMachinesIssues(data);
            });
    }),
    addChatMessage: action((state, payload) => {
        state.data.id === payload.issueId && state.data.chat.push(payload);
    }),
    addChatUser: thunk((actions, payload) => {
        return axios.post<null, IssueResponsePayload>(`${API.issue}/add-chat-user/${payload}`)
            .then(
                data => {
                    actions.addedChatUser();
                })
    }),
    addedChatUser: action((state) => {
        state.data.isChatUser = true;
    }),

    setOfflineFavoriteMachinesIssues: thunk(async (actions, payload) => {
        const IssueOffline = new IssueOfflineService();
        /*await*/ IssueOffline.reset(payload);
    }),
    fetchOfflineIssue: thunk(async (actions, payload) => {
        const IssueOffline = new IssueOfflineService();
        let issue = await IssueOffline.getIssue(payload);
        issue && actions.fetchedIssue(issue);
    }),
    setOfflineIssue: thunk(async (actions, payload) => {
        const IssueOffline = new IssueOfflineService();
        await IssueOffline.updateIssueDetail(payload);
    }),

    saveOfflineIssue: thunk(async (actions, payload) => {
        const IssueOffline = new IssueOfflineService();
        const response = await IssueOffline.saveIssue(payload);
        return response;
    }),

    updateIssueImage: thunk((actions, payload) => {
        actions.setIssueUpdating(true);
        return axios.post<null, void>(`${API.issue}/update-issue-image`, jsonToFormData(payload))
            .then(data => data)
            .finally(() => actions.setIssueUpdating(false))
    }),
    
    closeIssue: thunk(async (actions, payload) => {
        actions.setIssueUpdating(true);
        await axios.post<null, IssueResponsePayload>(`${API.issue}/close`, jsonToFormData(payload));
        actions.setIssueUpdating(false);
    })
};
