import React, { useMemo } from 'react';
import { required } from '~/app/helpers/validation';
import Loader from '~/app/common/Loader';
import useForm, { UseFormOptionFields } from '~/app/modules/form/useForm';
import { IssueClosePayload, IssueDetails } from '~/types';
import classes from './style.module.scss';
import { IssueCloseTypes } from '~/app/data/statuses/issue';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';
import Details from './Details';
import Footer from './Footer';

export type FormProps = {
  onClose(): void
  issueDetails: IssueDetails
  onSave?: (id: IssueDetails['id']) => void
}

export type FormComponent = React.FC<FormProps>

const Form: FormComponent = ({ onClose, issueDetails, onSave }) => {

  const formFields = useMemo<UseFormOptionFields>(() => ([
    ['issueCloseType', { validators: [['required', required]] }],
    ['comment', { validators: [['required', required]] }],
  ]), []);

  const { values, defaultValues, removeForm } = useForm<IssueClosePayload>({
    formName: 'closeIssue',
    fields: formFields,
    defaultValues: {
      issueCloseType: IssueCloseTypes.CORRECTED,
      comment: ''
    }
  });

  useDidMountEffect(() => (() => removeForm()));

  if (!values) {
    return <Loader size="32" />;
  }

  return (
    <>
      <div className={classes['container']}>
        <Details />
      </div>

      <Footer onClose={onClose} issueDetails={issueDetails} defaultFormValues={defaultValues} onSave={onSave} />
    </>
  );
};

export default Form;
