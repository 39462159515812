import ReactDOM from "react-dom";
import React, { useState } from "react";
import { useCanvasEditor } from "./useCanvasEditor";
import Dimmer from "~/app/common/Dimmer";
import TopControls from "./TopControls";
import EditControls from "./EditControls";
import ConfirmationDialog from "./ConfirmationDialog";
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import { useStoreState } from "~/store";

export interface ImageEditorProps {
    isOpen: boolean
    imageUrlOrObject: File | string | null
    imageSource?: string | null
    showDeleteOption?: boolean
    onClose?: () => void
    onImageSave: (editedImage: File | null) => void
    onDelete?: () => void
    onSubmitWithoutEdit?: () => void
}

export type ImageEditorComponent = React.FC<ImageEditorProps>

const ImageEditor: ImageEditorComponent = ({ isOpen, imageUrlOrObject, imageSource, showDeleteOption, onClose, onDelete, onImageSave, onSubmitWithoutEdit }) => {
    const { 
        canvasRef,
        historyRef,
        brushColor, 
        setBrushColor, 
        isDrawingActive, 
        toggleDrawingMode, 
        undoAction, 
        redoAction,
        editedImageFile,
        historyIndex,
        resetCanvas,
        checkAndSaveEditedImage
    } = useCanvasEditor(imageUrlOrObject, isOpen);

    const { t } = useTranslation("issue.imageEditor");
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [confirmAction, setConfirmAction] = useState(() => () => {});
    const isIssueDetailPage = useStoreState((state) => state.app.appHeader.isIssueDetailPage);

    const hasCanvasChanged = () => {
        if (!canvasRef.current) return false;
    
        return canvasRef.current.getObjects().some(obj => obj.type !== 'image');
    };

    const openConfirmation = (text, action) => {
        setConfirmationText(text);
        setConfirmAction(() => action);
        setIsConfirmationOpen(true);
    };

    const handleAbortEdit = () => {
        setIsConfirmationOpen(false);
        onClose?.();
    };

    const abortAction = () => {
        if (isDrawingActive) return resetCanvas();

        const message = hasCanvasChanged() 
            ? t('confirmationMenu.discardChanges') 
            : (imageSource === 'CAMERA' ? t('confirmationMenu.discardImage') : null);

        message ? openConfirmation(message, handleAbortEdit) : onClose?.();
    };

    const handleSubmit = () => {
        if (isDrawingActive) {
            checkAndSaveEditedImage();
        } else {
            hasCanvasChanged() ? onImageSave(editedImageFile) : onSubmitWithoutEdit?.();
        }
    };

    const handleDeleteImage = () => {
        setIsConfirmationOpen(false);
        onDelete?.();
    };

    return ReactDOM.createPortal((
        <>
            <Dimmer show={isOpen} page={true}>
                <div className={classes['edit-image-box']}>
                    <TopControls 
                        isDrawingActive={isDrawingActive} 
                        isIssueDetailPage={isIssueDetailPage} 
                        onCancel={abortAction} 
                        onSubmit={handleSubmit} 
                        t={(key) => key} 
                    />
                    <div className="canvas-wrapper">
                        <canvas id="fabricCanvas"></canvas>
                    </div>
                    <EditControls 
                        isDrawingActive={isDrawingActive}
                        historyIndex={historyIndex}
                        historyLength={historyRef.current.length}
                        onUndo={undoAction}
                        onRedo={redoAction}
                        onDelete={() => openConfirmation(t('confirmationMenu.deleteImage'), handleDeleteImage)}
                        onToggleDrawing={isDrawingActive ? checkAndSaveEditedImage : toggleDrawingMode}
                        brushColor={brushColor}
                        onColorChange={setBrushColor}
                        isIssueDetailPage={isIssueDetailPage}
                        showDeleteOption={showDeleteOption}
                    />
                </div>
            </Dimmer>
            <ConfirmationDialog 
                isOpen={isConfirmationOpen} 
                onConfirm={confirmAction} 
                onCancel={() => setIsConfirmationOpen(false)} 
                confirmationText={confirmationText} 
            />
        </>
    ), (document.getElementById('root') as HTMLElement));
};

export default ImageEditor;
