import React from 'react';
import Modal from '~/app/common/Modal';
import classes from './style.module.scss';
import Button from "~/app/common/Button";
import useTranslation from '~/app/hooks/useTranslation';

export type ConfirmationMenuProps = {
    isOpen?: boolean,
    onConfirm(): void,
    onCancel(): void,
    confirmationText: string
}

export type ConfirmationMenuComponent = React.FC<ConfirmationMenuProps>

const ConfirmationMenu: ConfirmationMenuComponent = ({ isOpen = false, onConfirm, onCancel, confirmationText }) => {
    const { t } = useTranslation('common.confirmActions');

    return (
        <Modal className={classes['confirmation-menu']} open={isOpen} position={'bottom'}>
            <div className={classes['model-header']}>
                <div className={classes['header-content']}>
                    <label className={classes['title']}>{confirmationText}</label>
                </div>
            </div>
            <div className={classes['modal-body']}>
                <div className={classes['actions']}>
                    <Button block={true} className={`${[classes['action-btn']]} ${classes['confirm-btn']}`} onClick={onConfirm}>{t('confirm')}</Button>
                    <Button block={true} className={`${[classes['action-btn']]} ${classes['cancel-btn']}`} onClick={onCancel}>{t('cancel')}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationMenu;
