import React, { useCallback, useState } from 'react';
import classes from './style.module.scss';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import AccessControl from '~/accessControl';
import Button from '~/app/common/Button';
import { useStoreActions, useStoreState } from '~/store';
import { IssueStatuses } from '~/app/data/statuses/issue';
import useTranslation from '~/app/hooks/useTranslation';
import { IssueDetails } from '~/types';
import CloseIssue from '~/issues/CloseIssue';

export type CloseIssueDialogComponent = React.FC;

const CloseIssueDialog = () => {
    const { t } = useTranslation('issue.issueClose');
    const [isOpenCloseIssueForm, setIsOpenCloseIssueForm] = useState<boolean>(false);
    const issueDetails = useStoreState(state => state.issue.data);
    const IssueFetchAction = useStoreActions(actions => actions.issue.fetchIssue);
    const IssueFetchOfflineAction = useStoreActions(actions => actions.issue.fetchOfflineIssue);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const setShowCompletedIssues = useStoreActions(actions => actions.issue.setShowCompletedIssues);

    const closeIssueClickHandler = useCallback((state: boolean) => (): void => {
        setIsOpenCloseIssueForm(state);
    }, []);

    const fetchIssueDetails = useCallback(async (id: IssueDetails['id']) => {
        await (netWorkStatus ? IssueFetchOfflineAction : IssueFetchAction)(id);
        setShowCompletedIssues(true);
    }, [IssueFetchAction, IssueFetchOfflineAction, netWorkStatus, setShowCompletedIssues]);

    if (netWorkStatus || issueDetails?.status !== IssueStatuses.REGISTERED) {
        return (<></>);
    }

    return (
        <>
            <AccessControl requiredPermissions={PERMISSION_GROUPS.closeIssues} >
                <div className={`${classes['button']}`}>
                    <Button className={`${classes['closeIssue']}`} onClick={closeIssueClickHandler(true)}> { t('closeIssue') } </Button>
                </div>

                <CloseIssue open={isOpenCloseIssueForm} onClose={closeIssueClickHandler(false)} issueDetails={issueDetails} onSave={fetchIssueDetails} />
            </AccessControl>
        </>
    );
};


export default CloseIssueDialog;