import React from "react";
import Button from "~/app/common/Button";
import classNames from "classnames";
import classes from './style.module.scss';

interface TopControlsProps {
    isDrawingActive: boolean;
    isIssueDetailPage?: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    t: (key: string) => string;
}

const TopControls: React.FC<TopControlsProps> = ({ isDrawingActive, isIssueDetailPage, onCancel, onSubmit, t }) => {
    return (
        <div className={classes['top-controls']}>
            <Button className={classNames(classes['abort-button'], { [classes['edit-mode']]: isDrawingActive })} onClick={onCancel}>
                {t('cancel')}
            </Button>
            {isDrawingActive && <span className={classes['edit-text']}>{t('marking')}</span>}
            {!isDrawingActive && isIssueDetailPage && <span className={classes['edit-text']}>{t('adjust')}</span>}
            <Button className={classNames(classes['submit-button'], { [classes['edit-mode']]: isDrawingActive })} onClick={onSubmit}>
                {t('ready')}
            </Button>
        </div>
    );
};

export default TopControls;