import React, { useCallback, useState } from 'react';
import Icon from "~/app/common/Icon";
import classes from './style.module.scss';
import Modal from '~/app/common/Modal';
import Menu from './Menu';
import AddIssue from '~/issues/Add';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import AccessControl from '~/accessControl';
import { IssueStatuses } from '~/app/data/statuses/issue';
import { useStoreActions, useStoreState } from '~/store';
import { IssueDetails, IssuePayload } from '~/types';

export type IssueOptionsComponent = React.FC;

const IssueOptions = () => {
    const [isOpenIssueOptions, setIsOpenIssueOptions] = useState<boolean>(false);
    const [openAddIssueDialog, setAddIssueDialog] = useState<boolean>(false);
    const issueType: IssuePayload['issueType'] = 'issue';
    const IssueFetchAction = useStoreActions(actions => actions.issue.fetchIssue);
    const IssueFetchOfflineAction = useStoreActions(actions => actions.issue.fetchOfflineIssue);
    const netWorkStatus = useStoreState(state => state.app.offline.status);

    const user = useStoreState(state => state.user.data);
    const issueDetails = useStoreState(state => state.issue.data);

    const issueOptionsClickHandler = useCallback((state: boolean) => (): void => {
        setIsOpenIssueOptions(state);
    }, []);

    const toggleAddIssueDialog = useCallback(() => {
        setAddIssueDialog(!openAddIssueDialog);
        setIsOpenIssueOptions(false);
    }, [openAddIssueDialog]);

    const fetchIssueDetails = useCallback(async (id: IssueDetails['id']) => {
        await (netWorkStatus ? IssueFetchOfflineAction : IssueFetchAction)(id);
    }, [IssueFetchAction, IssueFetchOfflineAction, netWorkStatus]);

    if (issueDetails?.status !== IssueStatuses.REGISTERED) {
        return (<></>);
    }

    return (
        <>
            <AccessControl requiredPermissions={Number(user.id) === Number(issueDetails?.issueCreatedUserId) ? PERMISSION_GROUPS.editOwnRegisteredIssues : PERMISSION_GROUPS.editIssuesRegisteredByOthers} >
                <Icon className={classes['icon']} name="more_vert" onClick={issueOptionsClickHandler(true)} />
                <Modal className={classes['issueOptionsModel']} open={isOpenIssueOptions} onClose={issueOptionsClickHandler(false)} position={'bottom'}>
                    <div>
                        <Menu onSelect={issueOptionsClickHandler(false)} onEdit={toggleAddIssueDialog} />
                    </div>
                </Modal>

                <AddIssue open={openAddIssueDialog} onClose={toggleAddIssueDialog} type={issueType} issueDetails={issueDetails} onSave={fetchIssueDetails} />
            </AccessControl>
        </>
    );
};


export default IssueOptions;