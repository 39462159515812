import React, { useState } from "react";
import Icon from "~/app/common/Icon";
import classes from './style.module.scss';
import ColorPicker from "~/app/common/ColorPicker";

interface EditControlsProps {
    isDrawingActive: boolean;
    historyIndex: number;
    historyLength: number;
    onUndo: () => void;
    onRedo: () => void;
    onDelete: () => void;
    onToggleDrawing: () => void;
    brushColor: string;
    onColorChange: (color: string) => void;
    isIssueDetailPage?: boolean;
    showDeleteOption?: boolean;
}

const EditControls: React.FC<EditControlsProps> = ({ 
    isDrawingActive, 
    historyIndex, 
    historyLength, 
    onUndo, 
    onRedo, 
    onDelete, 
    onToggleDrawing, 
    brushColor, 
    onColorChange,
    isIssueDetailPage,
    showDeleteOption
}) => {
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);    

    return (
        <div className={classes['edit-controls']}>
            {isDrawingActive && (
                <div className={classes['history-controls']}>
                    <Icon name="undo" size="16" className={`${classes['undo-icon']} ${historyIndex > 0 ? classes['active'] : ''}`} onClick={onUndo} />
                    <Icon name="redo" size="16" className={`${classes['redo-icon']} ${historyIndex < historyLength - 1 ? classes['active'] : ''}`} onClick={onRedo} />
                </div>
            )}
            {showDeleteOption && !isDrawingActive && (
                <Icon name="delete" size="16" className={classes['delete-icon']} onClick={onDelete} />
            )}
            <div className={classes['drawing-controls']}>
                {isDrawingActive && (
                    <>
                        <button
                            key={brushColor}
                            onClick={() => setIsColorPickerVisible(true)}
                            className={classes['current-color']}
                            style={{ backgroundColor: brushColor }}
                        />
                        <ColorPicker
                            isOpen={isColorPickerVisible}
                            currentColor={brushColor}
                            onColorChange={(color) => {
                                onColorChange(color);
                            }}
                            onClose={() => setIsColorPickerVisible(false)}
                        />
                    </>
                )}
                <Icon name="edit" size="24" className={`${classes['edit-icon']} ${isDrawingActive && classes['edit-mode']}`} onClick={onToggleDrawing} />
            </div>
        </div>
    );
};

export default EditControls;
