import { EDIT_IMAGE_DEFAULT_MIME_TYPE } from "~/app/data/statuses/issue";

// Convert Data URL to Blob
export const dataURLtoBolb = (dataURL: string) => {

    // Extract the Base64 string from the Data URL
    const byteString = atob(dataURL.split(',')[1]);

    // Extract MIME type (e.g., image/jpeg)
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    // Convert binary string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    return new Blob([uint8Array], { type: mimeString });
};

export const extractNameFromFileName = (fileName: string) => {
    return fileName
        ? fileName.split('.').slice(0, -1).join('.') || Date.now().toString()
        : Date.now().toString();
}

export const getImageFileInfo = (URL) =>  {
    const filename = URL.substring(URL.lastIndexOf('/') + 1).split('?')[0]; // Get filename without query params
    const extension = filename.split('.').pop(); // Extract file extension

    // Map common image extensions to MIME types
    const mimeTypes = {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
    };

    const mimeType = mimeTypes[extension.toLowerCase()] || EDIT_IMAGE_DEFAULT_MIME_TYPE; // Default if unknown

    return { filename, extension, mimeType };
}