import React from 'react';
import classes from './style.module.scss';
import Icon from "~/app/common/Icon";
import {FilePayload} from "./../index"
import { useStoreState } from '~/store';

export interface PreviewProps {
    files: FilePayload[]
    removeFile: (index: number) => void
    editFile: (index: number) => void
}

export type PreviewComponent = React.FC<PreviewProps>

const Preview: PreviewComponent = ({files, removeFile, editFile}) => {
    const netWorkStatus = useStoreState(state => state.app.offline.status);

    return (
        <div className={classes['file-preview-container']}>
            {files.map((file, index) =>
                <div className={classes['preview']} key={index}>
                    <img src={file.previewUrl} alt="..." onClick={() => editFile(index)}/>
                    <div className={classes['actions']} >
                        {!!!netWorkStatus && <Icon name={'edit'} className={`${classes['icon']} ${classes['edit']}`} onClick={() => editFile(index)}/>}
                        <Icon name={'remove_circle'} className={`${classes['icon']} ${classes['delete']}`} onClick={() => removeFile(index)}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Preview
