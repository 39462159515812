import React from 'react';
import useForm from '~/app/modules/form/useForm';
import { IssueClosePayload } from '~/types';
import classes from './style.module.scss';
import MForm from '~/app/modules/form';
import Textarea from '~/app/modules/form/Textarea';
import useTranslation from '~/app/hooks/useTranslation';
import RadioCheckGroup from '~/app/modules/form/RadioCheckGroup';
import { useIssueCloseOptions } from '~/app/data/statuses/issue';

export type DetailsComponent = React.FC;

const Details: DetailsComponent = () => {
  const { t } = useTranslation('issue.issueClose');
  const { values: formValues, fieldEvents: formFieldEvents } = useForm<IssueClosePayload>({ formName: 'closeIssue' });

  const options: { label: string, value: string }[] = useIssueCloseOptions();

  return (
    <>
      <MForm className={`${classes['form']} ${classes['details']}`}>
        <RadioCheckGroup
          name="issueCloseType"
          options={options}
          onChange={formFieldEvents.onChange}
          inputClassName={classes['radio-check']}
        />
        <Textarea
          className={`${classes['input-container']}`}
          placeholder={t('comment.placeholder')}
          descriptionPosition="top"
          name="comment"
          defaultValue={formValues.comment}
          {...formFieldEvents}
        />
      </MForm>
    </>
  );
};

export default Details
