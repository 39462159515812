import React, { useCallback } from 'react';
import classes from './style.module.scss';
import Button from 'app/common/Button/index';
import useTranslation from 'app/hooks/useTranslation';
import useForm, { FormValues } from "~/app/modules/form/useForm";
import { IssueClosePayload, IssueDetails, Machine } from "~/types";
import { useStoreActions, useStoreState } from "~/store";
import { toast } from "react-toastify";
import { checkIsObjectPropertiesEqual } from '~/app/helpers/object';
import { notify } from '~/app/helpers/notify';
import { useParams } from "react-router-dom";
import useWebSocket from '~/app/hooks/useWebSocket';

export interface FooterProps {
    onClose: () => void
    issueDetails: IssueDetails
    defaultFormValues?: FormValues<IssueClosePayload>
    onSave?: (id: IssueDetails['id']) => void
}

export type FooterComponent = React.FC<FooterProps>;

const Footer: FooterComponent = ({ onClose, issueDetails, defaultFormValues, onSave }) => {
    const { t } = useTranslation('issue');
    const { id } = useParams<{ id: Machine['id'] }>();
    const { values: formValues, formEvents: { onSubmit }, formState } = useForm<IssueClosePayload>({ formName: 'closeIssue' });
    const MachineFetchAction = useStoreActions(actions => actions.machine.fetchMachine);
    const closeIssueAction = useStoreActions(actions => actions.issue.closeIssue);
    const issueUpdating = useStoreState(state => state.issue.issueUpdating);
    const MachineOfflineFetchAction = useStoreActions(actions => actions.machine.fetchOfflineMachine);
    const MachineInFavorite = useStoreState(state => state.machines.inFavorite);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const { syncClosedIssue } = useWebSocket();
    const issueId = issueDetails.id;
    const departmentId = issueDetails.issueDepartmentId;

    const saveClickHandler = useCallback((): void => {
        onSubmit(({ formState: { invalid } }) => {
            if (invalid) {
                toast.error(t('error.required'));
                return;
            }
            const payload: IssueClosePayload = {
                ...formValues,
                ...(issueId && { issueId }),
            } as IssueClosePayload;

            closeIssueAction(payload).then(async (data) => {

                onClose();
                issueId && onSave?.(issueId);
                notify(t(`success.closed`), 'success');

                if (netWorkStatus === 0) {
                    syncClosedIssue({issueId: issueId, departmentId: departmentId});
                }

                await (netWorkStatus ? MachineOfflineFetchAction : MachineFetchAction)(id ?? issueDetails?.machineId);
                (netWorkStatus === 0 && MachineInFavorite(id ?? issueDetails?.machineId));
            });
        })();
    }, [onSubmit, formValues, issueId, closeIssueAction, t, onClose, onSave, netWorkStatus, MachineOfflineFetchAction, MachineFetchAction, id, issueDetails?.machineId, MachineInFavorite, syncClosedIssue, departmentId]);

    const isDisabled = (): boolean => {
        let isDisabled = true;
        if (formValues.issueCloseType && formValues.comment && formState.touched && !formState.invalid) {
            isDisabled = false;
        }
        if (issueId && formValues && defaultFormValues && !isDisabled) {
            const keysToCheck = ["issueCloseType", "comment"];
            isDisabled = checkIsObjectPropertiesEqual(defaultFormValues, formValues, keysToCheck);
        }
        return isDisabled;
    }

    return (
        <div className={`${classes['footer']}`}>
            <Button
                className={classes['abort']}
                onClick={onClose}>{t('cancel')}</Button>
            <Button
                className={classes['submit']}
                disabled={isDisabled()}
                loading={issueUpdating}
                onClick={saveClickHandler}>{t('issueClose.closeTheIssue')}</Button>
        </div>
    );
};

export default Footer
