import {WebSocketContext} from '~/webSocket/WebSocketProvider';
import {useCallback, useContext} from "react";
import {useStoreState} from "~/store";
import {ClosedIssueWebSocketData, DepartmentChangeWebSocketData, Message} from '~/types';
import store from "~/store";
import useTranslation from "~/app/hooks/useTranslation";
import { ChatWebSocketActions } from '../data/statuses/chat';

const useWebSocket = () => {
    const {reconnect, send, close} = useContext(WebSocketContext);
    const user = useStoreState(state => state.user.data);
    const issue = useStoreState(state => state.issue.data);
    const {t} = useTranslation('issue');
    const newIssueMsg = t('success.issue');

    const prepareMessage = useCallback((messageText: string, action: string = '', issueId: (number | string) = ''): Message => {
        return {
            message: messageText,
            issueId: `${issueId}`,
            user: user.id,
            senderId: user.id,
            senderName: user.name,
            ...(action === 'add' && {actions: ChatWebSocketActions.ADDUSER}),
            ...(action === 'registeredIssue' && {actions: ChatWebSocketActions.REGISTERISSUE, actionName: 'registeredIssue'})
        }
    }, [user.id, user.name]);

    const sendMessage = useCallback((messageText: string, action: string = '') => {
        send && send(JSON.stringify(prepareMessage(messageText, action, issue.id)));
    }, [send, issue.id, prepareMessage]);

    const syncIssues = useCallback((issueIds: (number | string)[]) => {
        send && send(JSON.stringify({isNewIssue: 'newIssue'}));
        issueIds && issueIds.forEach((issueId) => {
            send && send(JSON.stringify({
                "addedDataForIssueBoard": {
                    'type': 'addedIssue',
                    'switchBetweenIssueAndWorkorder': 0,
                    'issueId': issueId
                }
            }));
            send && send(JSON.stringify(prepareMessage(newIssueMsg, 'registeredIssue', issueId)));
        });
    }, [send, newIssueMsg, prepareMessage]);

    const syncUpdatedIssueDetail = useCallback((issueId: (number | string)) => {
        issueId && send && send(
            JSON.stringify({
                "addedDataForIssueBoard": {
                    'type': 'updatedIssue',
                    'issueId': issueId,
                    'isFromDueDateFilter' : false,
                    'hasDueDateChanged' : false,
                    'searchForIssueCard' : false,
                    'dueDate': null,
                    'switchBetweenIssueAndWorkorder': 0,
                }
            })
        );
    }, [send]);

    const syncDepartmentChange = useCallback((data: DepartmentChangeWebSocketData) => {
        data.issueId && send && send(
            JSON.stringify({
                "addedDataForIssueBoard": {
                    'type': 'changedDepartment',
                    'issueIds': [data.issueId],
                    'oldDepartment' : String(data.oldDepartmentId),
                    'department' : String(data.newDepartmentId)
                }
            })
        )
    }, [send])

    const syncClosedIssue = useCallback((data: ClosedIssueWebSocketData) => {
        data.issueId && send && send(
            JSON.stringify({
                "addedDataForIssueBoard": {
                    'type': 'deletedIssue',
                    'issueId': data.issueId,
                    'department': data.departmentId,
                    'switchBetweenIssueAndWorkorder': 1
                }
            })
        )
    }, [send])

    const connectUser = useCallback(() => {
        const userId = store.getState().user.data.id;
        if (userId) {
            const message = {
                'user': userId,
                'connectionType': 'open'
            };
            send && send(JSON.stringify(message));
        }
    }, [send]);

    return {
        sendMessage,
        close,
        syncIssues,
        syncUpdatedIssueDetail,
        syncDepartmentChange,
        syncClosedIssue,
        connectUser,
        reconnect
    }
};

export default useWebSocket;
