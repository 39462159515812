import React from "react";
import ConfirmationMenu from "~/app/common/ConfirmationMenu";

interface ConfirmationDialogProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    confirmationText: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ isOpen, onConfirm, onCancel, confirmationText }) => {
    return (
        <ConfirmationMenu 
            isOpen={isOpen} 
            onConfirm={onConfirm} 
            onCancel={onCancel} 
            confirmationText={confirmationText}
        />
    );
};

export default ConfirmationDialog;
