import React from 'react';
import classes from './style.module.scss';
import Modal from '~/app/common/Modal';
import { IssueDetails } from '~/types';
import Header from './Header';
import Form from './Form';

export interface CloseIssueProps {
    open: boolean
    issueDetails?: IssueDetails
    onSave?: (id: IssueDetails['id']) => void
    onClose: () => void
}

export type CloseIssueComponent = React.FC<CloseIssueProps>;

const CloseIssue = ({ open, issueDetails, onSave, onClose }) => {
    return (
        <>
            <Modal className={classes['closeIssueModel']} open={open} onClose={onClose} position={'bottom'}>
                <Header />
                <Form onClose={onClose} issueDetails={issueDetails} onSave={onSave} />
            </Modal>
        </>
    );
};

export default CloseIssue;