import { useMemo } from 'react';

import useTranslation from '~/app/hooks/useTranslation';

import { getStatuses, StatusWithLabel } from './common';
import {TypesWithLabel} from "~/app/data/statuses/common";

export enum IssueTypes {
    MINOR = 0,
    CRITICAL = 1,
    MORECRITICAL = 2
}

export const IssueTypeRepaired = 3;

export enum IssueStatuses {
    REGISTERED = 0,
    INPROGRESS = 1,
    COMPLETED = 2,
    CLOSED = 3
}

type IssueStatusesData = StatusWithLabel<IssueStatuses>[]

interface UseIssueStatuses {
    (): IssueStatusesData
}

export const useIssueStatuses: UseIssueStatuses = () => {
    const { t } = useTranslation('issue.statuses');

    return useMemo<IssueStatusesData>(() => (
        getStatuses(IssueStatuses).map(key => ({ label: t(key), value: +key }))
    ), [t]);
};



type IssueTypesData = TypesWithLabel<IssueTypes>[]

interface UseIssueTypes {
    (): IssueTypesData
}

export const useIssueTypes: UseIssueTypes = () => {
    const { t } = useTranslation('issue.types');

    return useMemo<IssueTypesData>(() => (
        getStatuses(IssueTypes).map(key => ({ text: t(key), value: +key }))
    ), [t]);
};

export const DEFAULT_EDIT_COLOR = '#FF453A';

export const COLOR_OPTIONS  = ["#FFFFFF", "#000000", "#0A84FF", "#30D158", "#FFD60A", DEFAULT_EDIT_COLOR];

export const EDIT_IMAGE_DEFAULT_EXTENSION = "png";

export const EDIT_IMAGE_DEFAULT_MIME_TYPE = "image/png";

export const EDIT_IMAGE_EXPORT_QUALITY = 1.0;

export const EDIT_IMAGE_RESOLUTION_MULTIPLIER = 3;

export enum IssueCloseTypes {
    CORRECTED = '2',
    NOTWRONG = '1'
}

export const DEFAULT_ISSUE_CLOSE_TYPE = IssueCloseTypes.CORRECTED;

export const useIssueCloseOptions = () => {
    const { t } = useTranslation('issue.issueClose');

    return useMemo(() => 
        Object.values(IssueCloseTypes).map(type => ({
            label: t(type === IssueCloseTypes.CORRECTED ? 'corrected' : 'notWrong'),
            value: type
        }))
    , [t]);
};
